import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import PropTypes from "prop-types";
import SEO from "../components/seo";
import Container from "../components/container.js";
import Image from "../components/image.js";
import GutenbergBlocks from "../components/gutenberg-blocks";
import { theme } from "../style/theme";

const InfoBar = styled.div`
  display: flex;
  min-width: 100%;
  max-width: none;
  color: ${theme.colors.black};
  margin: 1rem 0;
  justify-content: center;
`;
const InfoBarInnerDiv = styled.div`
  width: 50%;
  padding: 0 6rem;
  margin-bottom: 2rem;
  @media (max-width: 500px) {
    padding: 0 2rem;
  }
  p {
    font-size: 0.9rem;
    margin-top: 0;
  }
  &.left {
    text-align: end;
  }
  &.right {
    text-align: start;
  }
`;
const StyledImage = styled(Image)`
  height: 80vh;
  width: 100%;
  @media (max-width: 1024px) {
    height: 30vh;
  }
`;
const Title = styled.h1`
  text-align: center;
  @media (max-width: 500px) {
    font-size: 22px;
  }
`;
const Wrapper = styled.div`
  background-color: transparent;
  width: 100%;
`;
const Article = ({
  data: {
    wpPost: { title, excerpt, featuredImage, date, blocks, seo, categories },
  },
}) => {
  return (
    <Layout>
      <SEO
        title={seo?.title || title}
        description={seo?.metaDesc || excerpt}
        breadcrumbs={seo?.breadcrumbs}
      />
      <Container maxWidth="lg" disableAnimation disableMargin>
        <Wrapper>
          {featuredImage?.node && <StyledImage image={featuredImage.node} />}
        </Wrapper>
      </Container>
      <Container maxWidth="sm" disableAnimation disableMargin>
        <InfoBar>
          <InfoBarInnerDiv className="left">
            <p>{date}</p>
          </InfoBarInnerDiv>

          <InfoBarInnerDiv>
            {categories?.nodes?.map((cat, index) => (
              <p key={index}>{cat.name}</p>
            ))}
          </InfoBarInnerDiv>
        </InfoBar>
        <Title>{title}</Title>
        <GutenbergBlocks blocks={blocks} />
      </Container>
    </Layout>
  );
};

Article.propTypes = {
  data: PropTypes.object,
};

export default Article;

export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      blocks {
        ...GutenbergBlocks
      }
      categories {
        nodes {
          name
        }
      }
      title
      uri
      excerpt
      seo {
        metaDesc
        title
        breadcrumbs {
          text
          url
        }
      }
      date(formatString: "DD.MM.YYYY")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
